
import moment from 'moment';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useApi } from '../composables/useApi';
import SnackbarComposables from '../composables/SnackbarComposables';

const ProductService = () => 
{
    /* Data */
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();
    const ApiClient = useApi();
    const { addSnackbar } = SnackbarComposables();

    /* Methods */
    const indexProduct = async(date= moment().format('YYYY-MM-DD'), orderType = 'desc', orderBy, input, status="all", perPage = 15,  paginated = true) => 
    {
        store.commit('errors/CLEAR_STATE');
        let endpoint = `product/?date=${date}&orderType=${orderType}&status=${status}&paginated=${paginated}&per_page=${perPage}`;
        if(orderBy)
        {
            endpoint = endpoint+`&orderBy=${orderBy}`
        }
        if(input)
        {
            endpoint = endpoint+`&input=${input}`
        }

        const res = await ApiClient.get(endpoint);
        if(res)
        {
            return res.data;
        }
    };
    const storeProduct = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('product/store', payload);
        if(res.data) return res.data;
        return res
    };
    const storeFromCSV = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('product/store-from-csv', payload);
        if(res.data) return res.data;
        return res;
    };
    const storeExactMatch = async(product, payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post(`product/store-match/${product}`, payload);
        if(res) return res;
    };
    const showProduct = async(product) => 
    {
        store.commit('errors/CLEAR_STATE');
        let res = await ApiClient.get(`product/show/${product}`);
        if(res.data?.product) return res.data
        return router.push(`/${store.state.locale}/notFound`);
    };
    const updateProduct = async(product, payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.put(`product/update/${product}`, payload);
        if(res)
        {
            if(res.status === 200) 
            {
                addSnackbar('success', t('product.update.updatedMessage', store.state.locale));
                router.push(`/${store.state.locale}/dashboard`);
                return 200;
            }
            else 
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const updatePidProduct = async(product, payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.patch(`product/update-pid/${product}`, payload);
        if(res.data) return res.data;
        return res;
    };
    const destroyProduct = async(product) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.delete(`product/delete/${product}`);
        if(res)
        {
            if(res.status === 200)
            {
                addSnackbar('success', t('product.delete.deletedMessage', store.state.locale));
                router.push(`/${store.state.locale}/dashboard`);
                return 200;
            }
            else 
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }      
    };
    const searchExactMatch = async(product) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`product/search-match/${product}`);
        if(res)
        {
            if(res.data?.status)
            {
                return res.data;
            }
            else 
            {
                return res;
            }
        }
    };
    const fetchProduct = async (endpoint) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(endpoint);
        if(res)
        { 
            if(res.data)  
            {
                return res.data;
            }
            return res;
        }
    };

    const indexProductWithAssociations = async(date= moment().format('YYYY-MM-DD')) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`product/associations?date=${date}`);
        if(res)
        {
            return res.data;
        }
    };


    /* Hooks */
    locale.value = store.state.locale;

    return {
        /* Methods */
        indexProduct,
        storeProduct,
        storeFromCSV,
        storeExactMatch,
        showProduct,
        updateProduct,
        updatePidProduct,
        destroyProduct,
        searchExactMatch,
        fetchProduct,
        indexProductWithAssociations
    }
};

export default ProductService;
