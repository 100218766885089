<template>
    <div class="flex flex-col space-y-12 text-blue-500">
        <form method="POST"  @submit.prevent="newProduct" autocomplete="off" class="flex flex-col space-y-5 lg:space-y-12 h-full">
            <div class="flex flex-col space-y-5 lg:space-y-12 h-full">         
                <!-- Generals -->
                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-5 xl:gap-8 2xl:gap-10">
                    <div class="rounded w-ful bg-white dark:bg-gray-800 shadow p-5">
                        <!-- Name -->
                        <div class="mb-1 sm:mb-2 relative z-10">
                            <label for="name" class="inline-block mb-1 uppercase">{{ $t('product.create.name', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                            <input v-click-away="closeAlgolia" 
                                autocomplete="off" id="name" v-model="fields.name"  required
                                @click.prevent="open_algolia = true" name="name" 
                                :placeholder="$t('product.create.name', $store.state.locale)"  type="text" 
                                class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"/>
                            <span
                                v-if="$store.state.errors.errors.name" 
                                class="text-sm text-red-500" >
                                {{ $store.state.errors.errors.name[0] }}
                            </span>
                        </div>
                        <div class="mb-1 sm:mb-2 relative flex flex-col space-y-2">
                            <div class="flex items-center justify-between space-x-5">
                                <label for="formulator_name" class="inline-block mb-1 uppercase">{{ $t('product.create.formulator', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                            </div>
                            <button 
                                v-if="!Object.keys(selected_formulator).length"
                                :title="$t('product.create.select_formulator', $store.state.locale)"
                                class="flex flex-col border p-3 rounded border-blue-500"
                                @click.prevent="openSelectFormulatorModal">
                                {{ $t('product.create.select_formulator', $store.state.locale) }}
                            </button>
                            <button
                                v-else
                                class="flex flex-col space-y-1 border p-2 rounded text-left"
                                @click.prevent="openSelectFormulatorModal">
                                <h2 class="font-semibold">
                                    {{ selected_formulator.name }}
                                </h2>
                                <h4 v-if="selected_formulator.address" class="text-sm"> 
                                    <span>{{ selected_formulator.address.substring(0, 52) }}</span>
                                    <span v-if="selected_formulator.address.length > 52">...</span>
                                </h4>
                            </button>
                            <span
                                v-if="$store.state.errors.errors.formulator_name" 
                                class="text-sm text-red-500 mt-2" >
                                {{ $store.state.errors.errors.formulator_name[0] }}
                            </span>
                        </div>
                        <!-- PID -->
                        <div class="mb-1 sm:mb-2">
                            <label for="pid" class="inline-block mb-1 uppercase">PID<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                            <input 
                                id="pid" v-model="fields.pid" name="pid" 
                                placeholder="PID"  
                                type="text"
                                class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"/>
                            <span
                                v-if="$store.state.errors.errors.pid" 
                                class="text-sm text-red-500" >
                                {{ $store.state.errors.errors.pid[0] }}
                            </span>
                        </div>
                        <!-- GUID -->
                        <div class="mb-1 sm:mb-2">
                            <label for="guid" class="inline-block mb-1 uppercase">GUID<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                            <input 
                                id="guid" v-model="fields.guid" name="guid" 
                                placeholder="GUID"  
                                type="text"
                                class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"/>
                            <span
                                v-if="$store.state.errors.errors.guid" 
                                class="text-sm text-red-500" >
                                {{ $store.state.errors.errors.guid[0] }}
                            </span>
                        </div>
                        <!-- Quantity -->
                        <div class="mb-1 sm:mb-2">
                            <label for="quantity" class="inline-block mb-1 uppercase">{{ $t('product.create.quantity', $store.state.locale) }}  (kg)<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                            <input 
                                id="quantity" v-model="fields.quantity" required name="quantity" 
                                :placeholder="$t('product.create.quantity', $store.state.locale)"  
                                type="number" step="0.01"
                                class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"/>
                            <span
                                v-if="$store.state.errors.errors.quantity" 
                                class="text-sm text-red-500" >
                                {{ $store.state.errors.errors.quantity[0] }}
                            </span>
                        </div>
                        <!-- Reference Period -->
                        <div class="mb-1 sm:mb-2 flex flex-col space-y-2">
                            <label for="reference_date" class="inline-block mb-1 uppercase">{{ $t('product.create.reference_period', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                            <button 
                                class="flex items-center justify-between space-x-5 p-2 border rounded"
                                @click.prevent="() => selection_date_modal = true">
                                <span v-if="selected_month && selected_year" >{{ setReferencePeriod }}</span>
                                <div 
                                    class=" bg-transparent hover:bg-gray-200 dark:hover:bg-gray-900 duration-300 rounded p-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"/></svg>
                                </div>
                            </button>
                            <span
                                v-if="$store.state.errors.errors.reference_date" 
                                class="text-sm text-red-500" >
                                {{ $store.state.errors.errors.reference_date[0] }}
                            </span>
                        </div>
                        <p>
                            <span class="text-light-green-500 dark:text-blue-400">*</span>{{ $t('forms.required_field', $store.state.locale) }}
                        </p>
                    </div>
                    <div class="hidden md:block flex flex-col space-y-2">
                        <h1 class="text-3xl">
                            {{ $t('product.create.title', $store.state.locale) }}
                        </h1>
                        <hr class="border-gray-300">
                        <p class="">
                            {{ $t('product.create.description', $store.state.locale) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 md:gap-5 xl:gap-8 2xl:gap-10">
                <div class="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                    <button 
                        type="submit" 
                        title="Aggiungi utente"
                        class="flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                        :class="setSubmitClasses"
                        v-html="setSubmitContent"
                        :disabled="submitStatus !== 'void'">
                    </button> 
                </div>
            </div>
        </form>
        <teleport to="body">
            <SelectFormulatorModal 
                v-if="showSelectFormulatorModal"
                @closeModal="closeSelectFormulatorModal"
                @selectedFormulator="selectFormulator"
            />
            <!-- Selecte Reference Period modal -->
            <div v-if="selection_date_modal" class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
                <div class="flex flex-col w-full h-full items-center justify-center">
                    <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-6 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3">
                        <div class="flex items-start justify-between">
                            <button 
                                title="Chiudi"
                                @click.prevent="() => {selection_date_modal = false;}"
                                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                                    </svg>
                            </button>
                        </div>
                        <h1 class="text-xl flex items-center space-x-1">
                            <span>{{ $t('selectDateModal.title', $store.state.locale) }}</span> 
                        </h1>
                        <!-- Date Selection -->
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <!-- Month -->
                            <div class="mb-1 sm:mb-2">
                                <label for="from" class="inline-block mb-1 uppercase">{{ $t('selectDateModal.month', $store.state.locale) }}</label>
                                <div class="w-full relative">
                                    <select v-model="selected_month" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400">
                                        <option value="0" selected disabled>{{ $t('selectDateModal.month_select', $store.state.locale) }}</option>
                                        <option
                                            v-for="(m, i) in months"  :key="i"
                                            :value="i+1"  
                                        >
                                            {{ $t(`months.${m}`, $store.state.locale) }}
                                        </option>
                                    </select>
                                    <span class="absolute right-4 top-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                    </span>
                                </div>
                                <span
                                    v-if="$store.state.errors.errors.from" 
                                    class="font-bold text-sm text-red-500" >
                                    {{ $store.state.errors.errors.from[0] }}
                                </span>
                            </div>
                            <!-- Year -->
                            <div class="mb-1 sm:mb-2">
                                <label for="from" class="inline-block mb-1 uppercase">{{ $t('selectDateModal.year', $store.state.locale) }}</label>
                                <div class="w-full relative">
                                    <select v-model="selected_year" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400">
                                        <option value="0" selected disabled>{{ $t('selectDateModal.year_select', $store.state.locale) }}</option>
                                        <option
                                            v-for="(y, i) in years"  :key="i"
                                            :value="y"  
                                        >
                                            {{ y }}
                                        </option>
                                    </select>
                                    <span class="absolute right-4 top-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                    </span>
                                </div>
                                <span
                                    v-if="$store.state.errors.errors.from" 
                                    class="font-bold text-sm text-red-500" >
                                    {{ $store.state.errors.errors.from[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-end">  
                            <button 
                                :title="$t('actions.select', $store.state.locale)"
                                @click.prevent="() => {selection_date_modal = false}"
                                class="px-5 py-3 font-medium leading-5 text-center text-white bg-blue-500 rounded lg:mt-0 hover:bg-blue-300 duration-300 lg:w-auto">
                                {{ $t('actions.select', $store.state.locale) }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            <div v-if="showInventoryAlert" class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
                <div class="flex flex-col w-full h-full items-center justify-center">
                    <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-6 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3">
                        <div class="flex items-start justify-between">
                            <button 
                                title="Chiudi"
                                @click.prevent="closeInventoryAlert"
                                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                                    </svg>
                            </button>
                        </div>
                        <h1 class="text-xl text-red-500 flex items-center justify-center text-center space-x-1">
                            <span>{{ $t('inventory.already_exists', $store.state.locale) }}</span> 
                        </h1>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>
<script>
import moment from 'moment';
import { ref, reactive, computed, watch, onBeforeMount, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ProductService from '../../../services/ProductService';
import SnackbarComposables from '../../../composables/SnackbarComposables';
import InventoryService from '../../../services/InventoryService';

export default 
{
    components:
    {
        SelectFormulatorModal: defineAsyncComponent( () => import('../../../components/modals/SelectFormulatorModal.vue'))
    },
    setup()
    {
        /**
         *
         * Data
         *
         */
         const router = useRouter();
        const fields = reactive({});
        const store = useStore();
        const formulator_name = ref('');
        const submitStatus = ref('void');
        const {storeProduct} = ProductService();
        const { addSnackbar } = SnackbarComposables();
        const { getCountByDate } = InventoryService();
        const { t, locale } = useI18n();
        const formulators = ref([]);
        const search_formulators = ref([]);
        const selected_formulator = ref([]);
        const showSelectFormulatorModal = ref(false);
        const open_suggestions = ref(false);
        const open_algolia = ref(false);
        const selection_date_modal = ref(false);
        const showInventoryAlert = ref(false);
        const selected_month= ref(moment().format('M'));
        const selected_year= ref(moment().format('YYYY'));
        const months = ref([
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ]);
        const years = ref(Array.from({length: moment().format('YYYY') - 1900}, (value, index) => 1901 + index));


        watch(
            () => formulator_name.value,
            () => 
            {
                searchFormulator();
            }
        );

        /**
         *
         * Computed
         *
         */
        const setSubmitContent = computed( () => 
        {
            switch(submitStatus.value)
            {
                case 'void':
                    return t('product.create.submitContent',  locale.value);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return t('product.create.submitContent',  locale.value);
            }
        });
        const setSubmitClasses = computed( () =>
        {
            switch(submitStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        const setReferencePeriod = computed( () => 
        {
            return moment(`${selected_year.value}-${selected_month.value}-01`).format('YYYY-MM');
        });
        

        /**
         *
         * Methods
         *
         */
        const searchFormulator = () => 
        {
            search_formulators.value = [];
            if(formulator_name.value.length > 0)
            {
                return search_formulators.value = formulators.value.filter( e => e.name.trim().toLowerCase().includes(formulator_name.value.trim().toLowerCase()));
            }
            return search_formulators.value = formulators.value;
        };

        const selectFormulator = (formulator) => 
        {
            closeSelectFormulatorModal();
            selected_formulator.value = formulator;
            formulator_name.value = selected_formulator.value.name.trim();
        };

        const closeMenu = () => 
        {
            open_suggestions.value= false;
        }

        const newProduct = async () => 
        {
            store.commit('errors/CLEAR_STATE');
            //fields.reference_date = new Date();
            fields.reference_date = moment(`${selected_year.value}-${selected_month.value}-01`).format('YYYY-MM-DD');
            if(selected_formulator.value.id)
            {
                
                if(selected_formulator.value.name !== formulator_name.value)
                {
                    addSnackbar('warning', t('product.create.select_formulator', store.state.locale));
                }
                else 
                {
                    submitStatus.value = 'loading';
                    setTimeout( () => {if(submitStatus.value == 'loading') submitStatus.value = 'void'}, 5000);
                    store.commit('SET_REFERENCE_PERIOD', fields.reference_date);
                    fields.formulator_id = selected_formulator.value.id;
                    fields.supplierAID = selected_formulator.value.supplierAID || null;
                    const res = await storeProduct(fields);
                    if(res.product)
                    {
                        submitStatus.value = 'success';
                        setTimeout( () => submitStatus.value = 'void', 2000);
                        // Check for existing Inventory
                        const checkInventoryRes = await getCountByDate(fields.reference_date);
                        if(checkInventoryRes.inventories)
                        {
                            return showInventoryAlert.value = true;
                        }
                        else 
                        {
                            addSnackbar('success', t('product.create.createdMessage', locale.value));
                            return router.push(`/${store.state.locale}/dashboard`);
                        }
                    }
                    else if(res.message)
                    {
                        if(res.message == 'pid_already_stored') store.commit('errors/SET_ERRORS', {pid: [t('product.create.pid_already_stored', locale.value)]});
                        else if(res.message == 'guid_already_stored') store.commit('errors/SET_ERRORS', {guid: [t('product.create.guid_already_stored', locale.value)]});
                    }
                    submitStatus.value = 'error';
                    setTimeout( () => submitStatus.value = 'void', 2000);
                }
            }
            else 
            {
                addSnackbar('warning', t('product.create.select_formulator', store.state.locale));
            }
        }

        const openMenu = () => 
        {
            open_suggestions.value= true;
        }

        const closeAlgolia = () => 
        {
            return open_algolia.value = false;
        }
        const setProductName = (name) => 
        {
            fields.name = name;
            closeAlgolia();
        };
        const openSelectFormulatorModal = () => 
        {
            showSelectFormulatorModal.value = true;
            document.body.classList.add('overflow-hidden');
        };
        const closeSelectFormulatorModal = () => 
        {
            showSelectFormulatorModal.value = false;
            document.body.classList.remove('overflow-hidden');
        };
        const closeInventoryAlert = () => 
        {
            showInventoryAlert.value = false;
            document.body.classList.remove('overflow-hidden');
            router.push(`/${store.state.locale}/dashboard`);
        };

        /**
         *
         * Hooks
         *
         */
        locale.value = store.state.locale;
        moment.locale(store.state.locale);
        onBeforeMount( () => 
        {
            /* formulators.value = store.state.formulator.formulators;
            search_formulators.value = formulators.value; */
        });

        return {
            /* Data */
            submitStatus,
            fields,
            formulator_name,
            open_suggestions,
            open_algolia,
            search_formulators,
            showSelectFormulatorModal,
            selected_formulator,
            selection_date_modal,
            selected_month,
            selected_year,
            months,
            years,
            showInventoryAlert,
            /* Computed */
            setSubmitClasses,
            setSubmitContent,
            storeProduct,
            searchFormulator,
            selectFormulator,
            setReferencePeriod,
            /* Methods */
            closeMenu,
            newProduct,
            openMenu,
            setProductName,
            closeAlgolia,
            openSelectFormulatorModal,
            closeSelectFormulatorModal,
            closeInventoryAlert
        }
    }
}
</script>